/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, Key } from 'react';

import DoubleCalendarTicket from '../../Calendar/DoubleCalendarTicket';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useTranslation } from 'react-i18next';

//import SetCartItem from "../../Cart/SetCartItem";
import Button from '@restart/ui/esm/Button';
import Modal from 'react-bootstrap/Modal';

import { useAppSelector, useAppDispatch } from '../../../store/hooks';
import { addItemCart } from '../../../store/cart';

import SimpleAlert from '../../Alert/SimpleAlert/SimpleAlert';
import SlowBuyTicketPromotion from './SlowBuyTicketPromotion';
import DropdownHours from '../../DropdownHours/DropdownHours';
import i18next from 'i18next';
import api from '../../../services/api';
import ChanceUrlTicket from '../../../infra/ChangeUrlTicket';
import { useHistory, useParams } from 'react-router-dom';

export interface props {
  tourResponse: any;
  lng: any;
}

const SlowBuyTicket: React.FC<props> = ({ tourResponse, lng }: props) => {

  const url = window.location.href;
  const codeUrl = url.split('/');
  const slugUrl = codeUrl[codeUrl.length - 1].split('?')[0];
  const googleUrl = codeUrl[codeUrl.length - 1].split('?')[1] ? `?${codeUrl[codeUrl.length - 1].split('?')[1]}` : "";
  const googleUrlTransform = googleUrl.replaceAll(`?type=ticket`, "").replaceAll(`?type=ingresso`, "");
  const isTrilha = codeUrl[4].split('?')[2];
  
  const slug = useParams<any>();
  const history = useHistory<any>();

  const { t } = useTranslation();

  const [numberAdults, setNumberAdults] = useState<number>(0);
  const [numberChilds, setNumberChilds] = useState<number>(0);
  const [numberInfants, setNumberInfants] = useState<number>(0);
  const [numberStudent, setNumberStudent] = useState<number>(0);
  const [numberElders, setNumberElders] = useState<number>(0);
  const [numberPrivate, setNumberPrivate] = useState<number>(0);
  const [numberTotal, setNumberTotal] = useState<number>(1);
  const [numberStockTotal, setNumberStockTotal] = useState<number>(0);
  const [dateTour, setDateTour] = useState<any>([]);
  const [price, setPrice] = useState<any>([]);
  const [numberPeople, setNumberPeople] = useState<any>(null);
  const [showDropdown, setShowDropdown] = useState<any>(false);
  const [showCalendar, setShowCalendar] = useState<any>(false);
  const [showHours, setShowHours] = useState<any>(false);
  const [modalityNumber, setModalityNumber] = useState<any>(0);
  const [alert, setAlert] = useState<any>(false);
  const [alertPromo, setAlertPromo] = useState<any>(false);
  const [enableCart, setEnableCart] = useState<any>(false);
  const [alertMessage, setAlertMessage] = useState<any>('');

  const [ranges, setRanges] = useState<any>(null);

  const [lang, setLang] = useState<any>();

  const [isIntegration, setIsIntegration] = useState<any>(false);

  const [boxInfo, setBoxInfo] = useState<boolean>();
  const [modalityIndexError, setModalityIndexError] = useState<any>([]);

  const cartBPA: any = JSON.parse(
    JSON.stringify(useAppSelector((state: any) => state.cart)),
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (lng === 'BR') {
      setLang(tourResponse.slugBR);
    } else if (lng === 'EN') {
      setLang(tourResponse.slugEN);
    } else {
      setLang(tourResponse.slugES);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tourResponse]);

  const handleClick = (controll: string, faixa: string) => {
    let stateNumber: any;

    if (faixa === 'Adult') {
      stateNumber = numberAdults;
    } else if (faixa === 'Child') {
      stateNumber = numberChilds;
    } else if (faixa === 'Infant') {
      stateNumber = numberInfants;
    } else if (faixa === 'Elders') {
      stateNumber = numberElders;
    } else if (faixa === 'Student') {
      stateNumber = numberStudent;
    } else {
      stateNumber = numberPrivate;
    }

    let newNumber = numberAdults;

    if (controll === 'sub') {
      if (stateNumber === 0) {
        return false;
      } else {
        newNumber = stateNumber - ranges[`increment${faixa}`];
        if (ranges[`flagStock${faixa}`] === 1) {
          setNumberStockTotal(numberStockTotal - ranges[`increment${faixa}`]);
        }
      }
    }
    if (controll === 'plus') {
      if (ranges[`flagStock${faixa}`] === 1) {
        if (
          (numberStockTotal + ranges[`increment${faixa}`] <=
            dateTour[modalityNumber].maxStockNumber) && (numberStockTotal + ranges[`increment${faixa}`] <= 6)
        ) {
          newNumber = stateNumber + ranges[`increment${faixa}`];
          setNumberStockTotal(numberStockTotal + ranges[`increment${faixa}`]);
        } else {
          return false;
        }
      } else {
        newNumber = stateNumber + ranges[`increment${faixa}`];
      }
    }

    if (faixa === 'Adult') {
      return setNumberAdults(newNumber);
    } else if (faixa === 'Child') {
      return setNumberChilds(newNumber);
    } else if (faixa === 'Infant') {
      return setNumberInfants(newNumber);
    } else if (faixa === 'Elders') {
      return setNumberElders(newNumber);
    } else if (faixa === 'Student') {
      return setNumberStudent(newNumber);
    } else {
      return setNumberPrivate(newNumber);
    }
  };

  function changePriceDateTour(obj: any) {
    setModalityIndexError([]);
    setShowHours(true);
    setPrice(obj);
    setDateTour(obj);
    setNumberPeople({
      adults: '1',
      childs: '0',
      infants: '0',
      elders: '0',
      student: '0',
      globalPeople: '1',
    });
  }

  useEffect(() => {
    var aux: any = [];
    for (var i = 0; i < tourResponse.modalities.length; i++) {
      aux.push({
        adult: 0,
        child: 0,
        infant: 0,
      });
    }
    setDateTour(aux);
  }, [tourResponse.modalities]);

  useEffect(() => {
    setNumberTotal(
      numberAdults +
      numberChilds +
      numberInfants +
      numberElders +
      numberStudent +
      numberPrivate,
    );
  }, [numberAdults, numberChilds, numberInfants, numberElders, numberStudent, numberPrivate]);

  const addToCart = (itemOption: any, productCode: any) => {
    var repeatedItem: any = false; //impede de adicionar produto repetido no carrinho

    cartBPA.dados.forEach((elem: any) => {
      if (elem.productCode === productCode) {
        repeatedItem = true;
      }
    });

    if (repeatedItem === true) {
      setAlertMessage(t('tour.alertRepeatedItem'));
      setAlert(true);
    } else {
      const item = {
        productName: tourResponse.productName,
        productNameBR: tourResponse.productNameBR,
        productNameEN: tourResponse.productNameEN,
        productNameES: tourResponse.productNameES,
        imagesBaseUrl: tourResponse.imagesBaseUrl,
        imgCart: tourResponse.images[0],
        price: itemOption?.tarif?.price,

        idTarif: itemOption.idTarif,
        idPickup: itemOption.idPickup,

        priceAdults: itemOption.priceAdults,
        priceChilds: itemOption.priceChilds,
        priceInfants: itemOption.priceInfants,
        priceElders: itemOption.priceElders,
        priceStudent: itemOption.priceStudent,
        priceGlobalPeople: itemOption.priceGlobalPeople,
        reservationSystem: tourResponse.reservationSystem,

        ranges: ranges,
        hours: dateTour[modalityNumber].hours,

        sellingType: tourResponse.type === 'PRIVATIVO' ? 1 : 2,
        adults: itemOption.adults,
        childs: itemOption.childs,
        infants: itemOption.infants,
        elders: itemOption.elders,
        student: itemOption.student,

        globalPeople: itemOption.globalPeople,
        totalPeople: itemOption.totalPeople,

        productType: 4,
        productCode: tourResponse.productCode,
        productModCode: dateTour[modalityNumber].prodModUniqueCode,

        /* productSlug: slugUrl, */
        slugBR: tourResponse.slugBR,
        slugEN: tourResponse.slugEN,
        slugES: tourResponse.slugES,
        time: itemOption.time,
        date: itemOption.date,
        supplier: tourResponse.supplier,
        typePickup: itemOption.embarkingType,
        meetingPoint:
          itemOption.embarkingType === '0' ? itemOption.meetingPoint : '',
        pickupListId:
          itemOption.embarkingType === '0' ? 0 : itemOption.pickupListId,
        pickup: 0,

        discount: 0,
        customValueNet: 0,
        customValueSell: 0,
        goingSource: 'null',
        commingSource: 'null',
        latOrigem: 'null',
        lngOrigem: 'null',
        latDestino: 'null',
        lngDestino: 'null',
        cia: 'null',
        voo: 'null',
        smallSuitcase: 0,
        bigSuitcase: 0,
        internalNotes: ' ',
        idVeiculo: 0,
        passengers: [],

        channel: isTrilha === 'trilha' ? 7 : undefined,
      };

      //SetCartItem(item);
      dispatch(addItemCart(item));
      window.location.href = window.location.origin + '/checkout';
    }
  };

  const verifyItens = (option: any) => {
    if (
      numberAdults +
      numberChilds +
      numberInfants +
      numberElders +
      numberStudent +
      numberPrivate >
      0
    ) {
      auth(option);
    } else {
      setEnableCart(false);
      setAlertMessage(t('tour.alertSelectAPerson'));
      setAlert(true);
    }
  };

  const auth = (option: any) => {
    if (numberPeople === null || dateTour.length < 1) {
      setAlertMessage(t('tour.alertFillAllData'));
      setAlert(true);
    } else {
      option.adults = numberAdults;
      option.childs = numberChilds;
      option.infants = numberInfants;
      option.elders = numberElders;
      option.student = numberStudent;

      option.globalPeople =
        dateTour[modalityNumber].idSellingType === 1
          ? 1
          : numberAdults +
          numberChilds +
          numberInfants +
          numberElders +
          numberStudent +
          numberPrivate;
      option.totalPeople =
        dateTour[modalityNumber].idSellingType === 1
          ? numberPrivate
          : undefined;

      /* option.date = (document.getElementById(`date-hidden`) as HTMLInputElement).value.split('/').reverse().join('-'); */
      option.date = (
        document.getElementById(`date-${option.id}`) as HTMLInputElement
      ).value
        .split('/')
        .reverse()
        .join('-');
      option.time = dateTour[modalityNumber].time;

      /* const selectedDate = JSON.parse((document.getElementById(`date-hidden`) as HTMLInputElement).dataset.object || '{}'); */
      const selectedDate = JSON.parse(
        (document.getElementById(`date-${option.id}`) as HTMLInputElement)
          .dataset.object || '{}',
      );

      option.idTarif = selectedDate.idTarif;
      /* option.idPickup = (document.getElementById(`pickup`) as HTMLInputElement).value; */
      option.idPickup = '';

      option.priceAdults = selectedDate.priceAdultFinal;
      option.priceChilds = selectedDate.priceChildFinal;
      option.priceInfants = selectedDate.priceInfantFinal;
      option.priceElders = selectedDate.priceEldersFinal;
      option.priceStudent = selectedDate.priceStudentFinal;
      option.priceGlobalPeople = selectedDate.priceGlobalFinal;

      if (
        numberAdults +
        numberChilds +
        numberInfants +
        numberElders +
        numberStudent +
        numberPrivate >
        0
      ) {
        addToCart(option, tourResponse.productCode);
      } else {
        setAlertMessage(t('tour.alertSelectAPerson'));
        setAlert(true);
      }
    }
  };

  const handleChangeModalities = (value: any) => {
    setShowDropdown(false);
    setModalityNumber(Number(value));
    setShowCalendar(false);
    setShowHours(false);

    setTimeout(() => {
      setShowCalendar(true);
    }, 100);
  };

  useEffect(() => {
    setModalityIndexError([])
  }, [modalityNumber, dateTour])

  useEffect(() => {
    let date = new Date().toLocaleString('pt-BR');
    let dateNow = date
      .split(' ')[0]
      .split('/')
      .reverse()
      .join('')
      .replaceAll(',', '');

    const newSlug =
      i18next.language === 'pt'
        ? tourResponse.slugBR
        : i18next.language === 'en'
          ? tourResponse.slugEN
          : i18next.language === 'es'
            ? tourResponse.slugES
            : tourResponse.slugBR;
    if (
      slug?.slug !== newSlug &&
      newSlug !== undefined
    ) {
      window.location.href =
        window.location.origin + '/compra-rapida/' + newSlug + `?type=${ChanceUrlTicket(lng)}` + googleUrlTransform;
    } else {
    }

    if (`${dateNow}` <= '20240101') {
      setBoxInfo(true);
    } else {
      setBoxInfo(false);
    }

    if (`${dateNow}` <= '20240101') {
      setBoxInfo(true);
    } else {
      setBoxInfo(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18next.language]);

  useEffect(() => {
    setNumberAdults(0);
    setNumberChilds(0);
    setNumberInfants(0);
    setNumberStudent(0);
    setNumberElders(0);
    setNumberPeople(0);
    setNumberStockTotal(0);
    setNumberTotal(0);
    //setNumberTotalPeople(0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateTour[modalityNumber]?.time, dateTour[modalityNumber]?.data, modalityNumber]);

  useEffect(() => {
    console.log(modalityIndexError)
  }, [modalityIndexError]);

  useEffect(() => {
    if (slug.slug === 'bilhete-bondinho') {
      history.push(`/compra-rapida/bilhete-bondinho-pt?type=${ChanceUrlTicket(lng)}${googleUrlTransform}`)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {tourResponse.productCode !== 'TKT-ES99985' ? ( //Promoção Brasil - Caso necessite retirar a promoção, é só retirar essa comparação.
        <div className="container fast-buy-modal my-4">
          <h2 className="fast-buy-title">{tourResponse.productName}</h2>

          <div className="row fast-buy-content">
            <div className="col-12 col-md-5 fast-buy-description">
              <span
                id="description"
                dangerouslySetInnerHTML={{
                  __html: tourResponse.productInfo,
                }}
              ></span>
              <a
                href={
                  tourResponse.typeProduct === 'TOUR'
                    ? `/tour/${lang}`
                    : `/ticket/${lang}`
                }
                className="mt-3"
                style={{ color: '#000' }}
              >
                {t('slowBuy.ticket.moreDetails')}
              </a>
            </div>
            <div className="col-12 col-md-7 fast-buy-people">
              <h4 className="text-primary">{t('slowBuy.ticket.title')}</h4>
              <div>
                <div className="">
                  <div className="panel-dropdown-content right active">
                    <div className="select-date">
                      <div className="mb-5">
                        <p style={{ fontSize: '18px', color: '#707070' }}>
                          <strong>{t('slowBuy.ticket.passo01')}</strong>{' '}
                          {t('slowBuy.ticket.select01')}
                        </p>
                        <select
                          onChange={(e: any) => { handleChangeModalities(e.target.value); }}
                          className="form-control rounded-0 bg-white py-2 rounded modality-input"
                          name="modalities"
                          id="modalities"
                        >
                          <option value="" disabled selected>
                            {t('slowBuy.ticket.select01')}
                          </option>
                          {tourResponse.modalities.length > 0
                            ? tourResponse.modalities.map(
                              (item: any, index: Key) => {
                                return (
                                  <>
                                    <option value={index}>
                                      {item.modalityName}
                                    </option>
                                  </>
                                );
                              },
                            )
                            : ''}
                        </select>
                      </div>
                      <div className="mb-5">
                        {/* Permite mostrar calendário */}
                        {showCalendar === true &&
                          <>
                            {modalityIndexError.map((elem: any) => { return (elem.index) }).includes(modalityNumber) && modalityIndexError.find((elem: any) => { return (elem.index === modalityNumber) })?.errorOnCalendar
                              ?
                              <>
                                <div className="bg-light rounded d-flex flex-column justify-content-center align-items-center p-4">
                                  <FontAwesomeIcon
                                    icon={["fal", "times-circle"]}
                                    size="5x"
                                    style={{ fontSize: "3em", color: "#FF6600" }}
                                  />
                                  <div className="">
                                    <p className="my-1 text-center"><strong>{t('modalApiError.somethingWrong')}</strong></p>
                                    <p className="text-center">{modalityIndexError.find((elem: any) => { return (elem.index === modalityNumber) })?.text}</p>
                                  </div>
                                </div>
                              </>
                              :
                              <>
                                <p style={{ fontSize: '18px', color: '#707070' }}>
                                  <strong>{t('slowBuy.ticket.passo02')}</strong>{' '}
                                  {t('slowBuy.ticket.select02')}
                                </p>
                                <DoubleCalendarTicket
                                  modalityID={tourResponse.modalities[modalityNumber].id}
                                  product={tourResponse.modalities[modalityNumber]}
                                  productCode={tourResponse.productCode}
                                  changePriceDateTour={changePriceDateTour}
                                  dateTour={dateTour}
                                  index={modalityNumber}
                                  setRanges={setRanges}
                                  isIntegration={isIntegration}
                                  setIsIntegration={setIsIntegration}
                                  setApiError={setModalityIndexError}
                                />
                              </>

                            }
                          </>
                        }
                      </div>
                      {/* Permite mostrar hora e não tem erro no calendário */}
                      {showHours === true && !modalityIndexError.find((elem: any) => { return (elem.index === modalityNumber) })?.errorOnCalendar &&
                        <>
                          {modalityIndexError.map((elem: any) => { return (elem.index) }).includes(modalityNumber) && !modalityIndexError.find((elem: any) => { return (elem.index === modalityNumber) })?.errorOnCalendar
                            ?
                            <>
                              <div className="bg-light rounded d-flex flex-column justify-content-center align-items-center p-4">
                                <FontAwesomeIcon
                                  icon={["fal", "times-circle"]}
                                  size="5x"
                                  style={{ fontSize: "3em", color: "#FF6600" }}
                                />
                                <div className="">
                                  <p className="my-1 text-center"><strong>{t('modalApiError.somethingWrong')}</strong></p>
                                  <p className="text-center">{modalityIndexError.find((elem: any) => { return (elem.index === modalityNumber) })?.text}</p>
                                </div>
                              </div>
                            </>
                            :
                            <DropdownHours
                              changePriceDateTour={changePriceDateTour}
                              dateTour={dateTour}
                              index={modalityNumber}
                              setPeopleDisponible={setShowDropdown}
                              isIntegration={isIntegration}
                              info={tourResponse}
                              setApiError={setModalityIndexError}
                            />
                          }
                        </>
                      }

                      {showDropdown === true &&
                        <>
                          <p
                            style={{
                              fontSize: '18px',
                              color: '#707070',
                              marginTop: '3rem',
                            }}
                          >
                            <strong>
                              {dateTour[modalityNumber]?.hours?.length !== 1
                                ? 'Passo 04:'
                                : 'Passo 03:'}
                            </strong>{' '}
                            {t('slowBuy.ticket.select03')}
                          </p>

                          {ranges?.labelsRealSorted?.map(
                            (faixa: any, index: any) => {
                              let stateNumber: any;

                              if (faixa === 'Adult') {
                                stateNumber = numberAdults;
                              } else if (faixa === 'Child') {
                                stateNumber = numberChilds;
                              } else if (faixa === 'Infant') {
                                stateNumber = numberInfants;
                              } else if (faixa === 'Elders') {
                                stateNumber = numberElders;
                              } else if (faixa === 'Student') {
                                stateNumber = numberStudent;
                              } else {
                                stateNumber = numberPrivate;
                              }

                              return (
                                <>
                                  {ranges[`isActive${faixa}`] === true ? (
                                    <div className="qtyButtons">
                                      <div className="flex-ajuste">
                                        <label>{ranges[`label${faixa}`]}</label>
                                        <small id="idade-crianca" style={{ fontSize: '10px', color: "#707070" }}> {(dateTour[modalityNumber][`${faixa.toLowerCase()}Text`])}</small>
                                      </div>
                                      <div className="d-flex">
                                        <button
                                          className="qtyDec"
                                          onClick={() =>
                                            handleClick('sub', faixa)
                                          }
                                        >
                                          <span>-</span>
                                        </button>
                                        <input
                                          type="text"
                                          name="qtyInput"
                                          value={stateNumber}
                                          id="numero-adulto"
                                        />
                                        <button
                                          className="qtyInc"
                                          onClick={() =>
                                            handleClick('plus', faixa)
                                          }
                                        >
                                          <span>+</span>
                                        </button>
                                      </div>
                                      <div className="price">
                                        {faixa === 'Private' ? (
                                          <span
                                            className="price-dropdown text-dark font-weight-bold w-100 d-block"
                                            id="valor-adulto-sm"
                                          >
                                            R${' '}
                                            {price[modalityNumber].global
                                              .toFixed(2)
                                              .split('.')
                                              .join(',')}
                                          </span>
                                        ) : (
                                          <span
                                            className="price-dropdown text-dark font-weight-bold w-100 d-block"
                                            id="valor-adulto-sm"
                                          >
                                            R${' '}
                                            {price[modalityNumber][
                                              `${faixa.toLowerCase()}`
                                            ]
                                              .toFixed(2)
                                              .split('.')
                                              .join(',')}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              );
                            },
                          )}
                          {(numberStockTotal >=
                            dateTour[modalityNumber].maxStockNumber) || (numberStockTotal >= 6) ? (
                            <SimpleAlert alertType="danger">
                              {t('slowBuy.ticket.limit')}
                            </SimpleAlert>
                          ) : (
                            ''
                          )}
                          <hr />
                          <div className="subtotal flex-ajuste">
                            <h5>{t('slowBuy.ticket.sub')}</h5>
                            <span
                              className="price-dropdown text-dark font-weight-bold d-block"
                              id="valor-subtotal-sm"
                            >
                              {' '}
                              R${' '}
                              {(
                                price[modalityNumber].adult * numberAdults +
                                price[modalityNumber].child * numberChilds +
                                price[modalityNumber].infant * numberInfants +
                                price[modalityNumber].elders * numberElders +
                                price[modalityNumber].student * numberStudent +
                                (numberPrivate < 1 ? 0 : price[0].global)
                              )
                                .toFixed(2)
                                .split('.')
                                .join(',')}
                            </span>
                          </div>
                          {boxInfo === true ? (
                            <div className="box-info">
                              <small>{t('slowBuy.tour.boxInfo')}</small>
                              <br></br>
                              <small>{t('slowBuy.tour.boxInfoNextDate')}</small>
                            </div>
                          ) : (
                            <></>
                          )}
                          <div>
                            {
                              (tourResponse.productCode !== process.env.REACT_APP_MARAVILHA && tourResponse.productCode !== process.env.REACT_APP_CARIOCA_STATE) && tourResponse.productCode === process.env.REACT_APP_BILHETE_BONDINHO
                                ?
                                <small>
                                  {t("slowBuy.ticket.otherTextCapsula")}
                                </small>
                                :
                                (tourResponse.productCode !== process.env.REACT_APP_MARAVILHA && tourResponse.productCode !== process.env.REACT_APP_CARIOCA_STATE) && tourResponse.productCode !== process.env.REACT_APP_BILHETE_BONDINHO
                                  ?
                                  <small>
                                    {t("slowBuy.tour.otherTextCapsula")}
                                  </small>
                                  :
                                  <></>
                            }
                          </div>
                          {
                            tourResponse.productCode !== process.env.REACT_APP_CARIOCA_STATE
                              ?
                              <div className="warning mb-0">
                                <p>{t('slowBuy.ticket.alert')}</p>
                              </div>
                              :
                              <div className="warning mb-0">
                                <p>{t('slowBuy.ticket.textCariocaState')}</p>
                              </div>
                          }

                          <div style={{ lineHeight: '1.25rem' }}>
                            <small>
                              <strong>{t('slowBuy.tour.tip')}</strong>
                            </small>
                          </div>
                        </>
                      }
                    </div>
                  </div>
                </div>
              </div>

              {!modalityIndexError.map((elem: any) => { return (elem.index) }).includes(modalityNumber) &&
                <div className="buy-button">
                  {numberPeople !== null &&
                    <div className="d-flex flex-column align-items-center">
                      <button
                        title=""
                        className="btn btn-primary btn-block btn-add-cart link-add mb-1"
                        id=""
                        onClick={() =>
                          verifyItens(tourResponse.modalities[modalityNumber])
                        }
                        disabled={
                          (tourResponse.reservationSystem === 99 &&
                            cartBPA?.isProductBdB === true) ||
                            (tourResponse.reservationSystem === 0 &&
                              cartBPA?.isProductC2Rio === true)
                            ? true
                            : false
                        }
                      >
                        {t('productOptions.buy')}
                      </button>
                      {(tourResponse.reservationSystem === 99 &&
                        cartBPA?.isProductBdB === true) ||
                        (tourResponse.reservationSystem === 0 &&
                          cartBPA?.isProductC2Rio === true) ? (
                        <div className="bg-danger-custom">
                          <small className="text-center" style={{ fontSize: ".6em" }}>
                            {t("slowBuy.tour.textOperator")}
                          </small>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  }
                </div>
              }
            </div>

            <Modal
              className="modal-confirm"
              show={alert}
              onHide={() => {
                setAlert(false);
              }}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
            >
              <div className="modal-body text-center">
                <div>
                  <FontAwesomeIcon
                    icon={['fal', 'times-circle']}
                    size="5x"
                    style={{ fontSize: '7.5em', color: '#FF6600' }}
                  />
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: alertMessage,
                  }}
                  className="px-md-5"
                  style={{ padding: '0 3rem' }}
                ></div>
                <div>
                  <Button
                    type="submit"
                    className="btn btn-primary form-button px-4 py-2"
                    onClick={
                      enableCart === true
                        ? () => auth(tourResponse.modalities[modalityNumber])
                        : () => setAlert(false)
                    }
                  >
                    Fechar
                  </Button>
                </div>
              </div>
            </Modal>

            <Modal
              className="modal-confirm"
              show={alertPromo}
              onHide={() => {
                setAlertPromo(false);
              }}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
            >
              <div className="modal-body text-center">
                <div>
                  <FontAwesomeIcon
                    icon={['fal', 'times-circle']}
                    size="5x"
                    style={{ fontSize: '7.5em', color: '#FF6600' }}
                  />
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: alertMessage,
                  }}
                  className="px-md-5"
                  style={{ padding: '0 3rem' }}
                ></div>

                <div>
                  <Button
                    type="submit"
                    className="btn btn-primary form-button px-4 py-2"
                    onClick={() => setAlertPromo(false)}
                  >
                    Fechar
                  </Button>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      ) : (
        <SlowBuyTicketPromotion tourResponse={tourResponse} lng={lng} />
      )}
    </>
  );
};

export default SlowBuyTicket;
